import * as React from "react"
import Button from "../components/button"

const Accessibility = ({ title, content, image }) => (
  <div className="accessibility p40 bt1 flex m-wrap">
    <div className="w-50 m-100">
      <p className="m0 h4 mt40">{title}</p>
      <div
        className="max-600 medium mt40 mb40 break-space"
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <div className="pt40">
        <Button text="Read More" link="/contact" />
      </div>
    </div>
    <div className="w-50 m-100">
      <div className="max-400 mla mt40 mb40">
        <div className="ratio-1-2 bg-grey" />
      </div>
    </div>
  </div>
)

export default Accessibility
