import * as React from "react"
import Dropdowns from "../components/dropdowns"

const Services = ({ title, content, services }) => {
  return (
    <div className="services p40 bt1">
      <div className="flex mt40 mb40 m-wrap">
        <div className="w-50 m-100 m-mb40">
          <p className="m0 h4 mb40">{title}</p>
          <div className="content large max-400">{content}</div>
        </div>
        <div className="w-50 m-100">
          <Dropdowns dropdowns={services} />
        </div>
      </div>
    </div>
  )
}
export default Services
