import * as React from "react"

const SDA = ({ title, content, services, className = "" }) => {
  return (
    <div className={"sda p40 " + className}>
      <div className="flex m-wrap mt40">
        <div className="w-50 m-100">
          <div className="sticky mb40">
            <p className="m0 h4 mb40">{title}</p>
            <div className="large mr40 max-400">{content}</div>
          </div>
        </div>
        <div className="w-50 m-100">
          <div className="mb40">
            {services.map((service, index) => {
              return (
                <div
                  key={index}
                  className={
                    "content-block pb40 max-600 " +
                    (index === 0 ? "" : " pt40 ") +
                    (index === services.length - 1 ? "" : "bb1 b-grey")
                  }
                >
                  <p className="op-50 m0 mb20">{service.title}</p>
                  <div className="medium">{service.content}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
export default SDA
