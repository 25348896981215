import * as React from "react"
import Layout from "../components/layout"
import TitleRotating from "../components/titleRotating"
import Accessibility from "../components/accessibility"
import Process from "../components/contentTitle"
import Services from "../components/services"
import Sda from "../components/contentTitle"
import Faqs from "../components/faqs"
import Seo from "../components/seo"
import Cta from "../components/cta"
import { graphql } from "gatsby"

const CapabilitiesPage = ({ data }) => {
  return (
    <Layout>
 
      <TitleRotating title="Specialists in" words={['Interior Design','SDA','Planning', 'Access', 'Interior Design']} />
      
      <Services title="Services" services={data.capability.services} content={data.capability.serviceIntroduction}  />
      
      <Accessibility title="Accessibility" content={data.capability.accessibilityIntroduction }/>
      
      <Sda
        services={data.capability.sdaService}
        title="SDA Compliant Services"
      />
      
      <Process
        className="bt1"
        content={data.capability.sdaIntroduction}
        services={data.capability.processServices}
        title="Our Process"
      />
      
      <Faqs
        general={data.capability.generalFaqs}
        accessibility={data.capability.accessibilitySda}
      />
      <Cta />
    </Layout>
  )
}

export default CapabilitiesPage

export const Head = () => <Seo title="Capabilities" />

export const query = graphql`
  query CapabilityQuery {
    capability: datoCmsCapabilityPage {
      serviceIntroduction
      services {
        title
        content
      }
      sdaIntroduction
      sdaService {
        content
        title
      }
      processServices {
        content
        title
      }
      generalFaqs {
        title
        content
      }
      accessibilitySda {
        title
        content
      }
      accessibilityTitle
      accessibilityIntroduction
    }
  }
`
