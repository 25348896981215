import * as React from "react"
import Dropdowns from "../components/dropdowns"

const Faqs = ({ general, accessibility }) => {
  return (
    <div className="faqs p40 bt1 mb40">
      <div className="flex m-wrap">
        <div className="w-50 m-100">
          <p className="m0 h4 mb40 mt40 sticky">FAQ’S</p>
        </div>
        <div className="w-50 m-100">
          <div className="faqs mt40">
            <p className="m0 op-50 mb40 medium">General</p>
            <Dropdowns dropdowns={general} />
            <p className="op-50 mt80 mb80 medium">Accessibility and SDA</p>
            <Dropdowns dropdowns={accessibility} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faqs
